
#home {
  .contactList {
    li {
      a {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }
      padding: .5em;
      i {
        font-size: 1.25em;
        margin-right: .5em;
      }
    }
  }
}