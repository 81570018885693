/** Reset CSS **/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}

/* remember to define focus styles! */
:focus {
  outline: 0;
}

body {
  line-height: 1;
  color: black;
  background: white;
}

ol,
ul {
  list-style: none;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: separate;
  border-spacing: 0;
}

caption,
th,
td {
  text-align: left;
  font-weight: normal;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
}

blockquote,
q {
  quotes: "" "";
}

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@import "../fonts/css/fontello.css";

body {
  margin: 0;
  color: #2e2e2e;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px !important;
  line-height: 1.75rem;

  font-family: monospace;
  font-size: 18px;
}

body #root {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  margin-top: 1em;
  margin-bottom: .5em
}

h1,
.h1 {
  font-size: 2rem
}

h2,
.h2 {
  font-size: 1.5rem
}

h3,
.h3 {
  font-size: 1.25rem
}

h4,
.h4 {
  font-size: 1rem
}

h5,
.h5 {
  font-size: .875rem
}

h6,
.h6 {
  font-size: .75rem
}

p {
  margin: .75em 0;
}

strong {
  font-weight: bold;
}

a {
  text-decoration: underline;
  color: #4487c8;
}

a:hover {
  color: inherit;
}

a.invert {
  text-decoration: none;
  color: inherit;
}

a.invert:hover {
  color: #4487c8;
}

.body-font {
  // font-family: "Merriweather", sans-serif;
}

@media (min-width: 768px) {
  .flex-container {
    display: flex;
    margin: -.5em;
  }

  .flex-container>div {
    margin: .5em;
  }

  .flex-1 {
    flex: 1;
  }

  .flex-2 {
    flex: 2;
  }

  .flex-3 {
    flex: 3;
  }

  .flex-4 {
    flex: 4;
  }

  .flex-5 {
    flex: 5;
  }

  .flex-6 {
    flex: 6;
  }
}


.green-text {
  color: #87c844;
}

.dark-green {
  color: #6da831;
}

.light-green-text {
  color: #f9fcf5;
}

.green-background {
  background: #87c844;
}

.light-green-background {
  background: #f9fcf5;
}

@import 'colors';
@import 'components';