@media (min-width: 768px) {
  .flex {
    display: flex;

    div {
      flex: 1;
      padding: 8px;
    }

    & > div > img {
      padding: 1em;
    }
  }
}

.project-img {
  height: auto;
  &.auto-height {
  }
  &.full-width {
    width: 100%;
  }
  &.half-width {
    width: 50%;
  }
  cursor: pointer;
}