@import 'colors';

.header {
  background: $green;

  nav {
    //display: flex;
    a {
      //flex-basis: 0;
      //display: inline-block;
      display: block;
      font-family: "Montserrat", sans-serif;
      font-size: 1.25em;
      padding: .5em;
      text-decoration: none;
      color: white;
      white-space: nowrap;
      :hover {
        background: $darkGreen;
      }
    }
    span {
      flex: 1 0;
    }
  }
}

@media (min-width: 768px) {
  nav {
    display: flex;
    a {
      flex-basis: 0;
      display: inline-block;
    }
  }
}

.footer {
  background: $lightGray;
  display: flex;
  padding: 1em;

  div:first-child {
    flex: 1;
  }

  div:last-child {
    flex: 0;
    white-space: nowrap;
    a {
      font-size: 1.25em;
      margin: 0 8px;
    }
  }
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  &.centered {
    text-align: center;
  }
  padding: 1em;
  background: #f9fcf5;
  border-bottom: 1px solid #87c844;
  h1 {
    font-size: 3rem;
    margin: .5em 0;
  }
  h2 {
    margin: 0 0 .5em;
    color: $gray;
  }

  img {
    border-radius: 50%;
    width: 125px;
    height: 125px;
    box-shadow: 0 0 8px rgba(0, 0, 0, .3);
    &:hover {
      box-shadow: 0 0 16px rgba(0, 0, 0, .3);
    }
  }
}

.container {
  width: 100%;
  max-width: 800px;
  margin: auto;
  padding: 10px;
}

@media (min-width: 810px) {
  .container{
    padding: 0;
  }
}

.row {
  padding: 2em;
  &:nth-of-type(2n+1) {
    background: $lightGreen;
    clip-path: polygon(0 0%, 100% 4%, 100% 96%, 0% 100%);

    @media (min-width: 768px) {
      clip-path: polygon(0 0%, 100% 9%, 100% 91%, 0% 100%);
    }
  }
}



ul.skills {
  display: grid;
    grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;

  li {
    display: inline-block;
    text-align: center;
    padding: 8px;
    font-size: 10px;
    line-height: 1.5;

    img {
      display: block;
      margin: auto;
      width: 95%;
      max-width: 100px;
      padding: 3px;
      border: 1px solid #eeeeee;
    }
  }

  &.small-img {
    li img {
      max-width: 50px;
    }
  }
}

@media (min-width: 768px) {
  ul.skills {
    grid-template-columns: repeat(6, 1fr);
    &.ten-col {
      grid-template-columns: repeat(10, 1fr);
    }
  }
}

.img-overlay {
  position: fixed;
  background: rgba(255, 255, 255, .9);
  top: 0;
  overflow: auto;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 4em;
  transition: all ease .5s;
  opacity: .0;

  img {
    width: 100%;
  }

  &[show] {
    opacity: 1;
  }

  .close {
    position: fixed;
    top: 0em;
    right: .5em;
    font-size: 3em;
    border: 0;
    background: transparent;
    cursor: pointer;
  }
}